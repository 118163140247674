<template>
	<v-navigation-drawer
		v-model="dialog"
		absolute
		right
		light
		app
		temporary
		width="544px"
		@input="watchDrawer"
	>
		<v-app-bar flat color="primary" height="75" absolute width="100%">
			<div class="header-drawer d-flex justify-space-between align-center">
				<span>Adicionar vaga</span>

				<v-btn color="#fff" fab icon @click="setMask" class="button">
					<v-icon>close</v-icon>
				</v-btn>
			</div>
		</v-app-bar>

		<p class="description">
			Preencha as informações referente a vaga para publicação
		</p>

		<Form />
	</v-navigation-drawer>
</template>

<script>
import overlayDrawer from '@/mixins/overlayDrawer'
import Form from '@/components/unique/Teams/JobVacancies/Form'

export default {
	data: () => ({
		load: false,
	}),
	mixins: [overlayDrawer],
	components: {
		Form,
	},
}
</script>

<style lang="scss" scoped>
.header-drawer {
	width: 100%;
	span {
		color: #fff;
	}
}

.title {
	color: #fff !important;
	font-weight: 500;
	line-height: 32px;
}

.description {
	width: 90%;
	margin-top: 70px;
	padding: 32px 0 0 20px;
}
</style>
