<template>
	<div class="form">
		<v-text-field label="Título da vaga" />
		<vue-simplemde v-model="content" ref="markdownEditor" />
	</div>
</template>

<script>
import VueSimplemde from 'vue-simplemde'

export default {
	data: () => ({
		content: '',
	}),
	components: {
		VueSimplemde,
	},
}
</script>

<style>
@import '~simplemde/dist/simplemde.min.css';

.form {
	padding: 20px;
}
</style>
